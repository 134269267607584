/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import Grid from "~components/styles/Grid.jsx";
import * as T from "~components/styles/Typography.jsx";
import Button from "~components/Button.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";

const StyleGuidePage = () => (
  <>
    <SEO noIndex />

    <Layout>
      {/* These prevent TW from purging Typography.jsx styles */}
      <h1>keep me</h1>
      <h2>keep me</h2>
      <h3>keep me</h3>
      <h4>keep me</h4>
      <h5>keep me</h5>
      <h6>keep me</h6>
      <p>keep me</p>

      <Grid node="section" styles={[tw`pt-24`]}>
        <ul tw="col-span-12 pb-6 border-b">
          {Array(4)
            .fill(null)
            .map((_, headingIndex) => {
              const key = `style-guide-heading-${headingIndex}`;

              return (
                <li key={key} tw="mt-6 mb-6">
                  <T.Heading
                    key={key}
                    font={(headingIndex + 1).toString()}
                    level={(headingIndex + 1).toString()}
                  >
                    {`h${headingIndex + 1}: Love + Money`}
                  </T.Heading>
                </li>
              );
            })}
        </ul>

        {/* // */}

        <ul tw="col-span-12 pb-6 border-b">
          {Array(3)
            .fill(null)
            .map((_, bodyIndex) => {
              const key = `style-guide-body-${bodyIndex}`;

              return (
                <li key={key} tw="mt-6 mb-6">
                  <T.Body
                    key={key}
                    font={(bodyIndex + 1).toString()}
                    level={(bodyIndex + 1).toString()}
                  >
                    {`b${bodyIndex + 1}: Love + Money`}
                  </T.Body>
                </li>
              );
            })}
        </ul>

        <div tw="col-span-12 pt-6 pb-6 border-b">
          <T.Caption>Caption: Love + Money</T.Caption>
        </div>

        <div tw="col-span-12 pt-6 pb-6 border-b">
          <Button styles={[tw`border py-2 px-6`]} text="Love + Money" />
        </div>
      </Grid>
    </Layout>
  </>
);

export default StyleGuidePage;
